
/**
 * Create the store with dynamic reducers
 */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createReducer from './reducers';

const loggingMiddleware = (store) => (next) => (action) => {
    
    if(action.status===403 && action.error){
        // webConst.toaster(webConst.TOAST_ERROR, action.error.responseMessage);
    }
    next(action);
}


export default function configureStore() {
    const store = createStore(createReducer,
        applyMiddleware(thunk, loggingMiddleware)
        // applyMiddleware(thunk, logger,loggingMiddleware)
    );
    return store;
}
