import { HOME_SUCCESS, HOME_FAIL } from './Constants';

const initialState = {
  isLoading: true,
  success:[],
  fail:[]
};
export default function meetingEventsReducer(state = initialState, action) {
  switch (action.type) {
    case HOME_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status
      };
    case HOME_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status
      };
    default:
      return state;
  }
}