import { HOME_SUCCESS, HOME_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;


export const getMeetingEventsRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.MEETING_EVENTS}`,value)
        .then((response) => {
            if(response.data.responseCode===200)
            {
                dispatch(homeSuccessResult(response.data.responseBody,response.status));
            }
            else{
                dispatch(homeFailResult(response.data.responseMessage,response.data.responseCode));
            }
        })
        .catch((error) => {
            dispatch(homeFailResult(error.data,error.status));
        });
    }
};

/*
    API RESULT
 */

export const homeSuccessResult = (success,status) => ({
    type: HOME_SUCCESS,
    success,
    status
});

export const homeFailResult = (error,status) => ({
    type: HOME_FAIL,
    error,
    status
});
