import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './Footer.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic_title: '',
    };
  }

  componentDidUpdate = prevProps => {
    
  }

  render() {
    return (
      <footer className="bg-light py-2 mt-3 mb-2">
        <div className="container">
          <div className="small text-center text-muted fh-footer"><Translate id="copyright" /> © {new Date().getFullYear()} - {this.state.clinic_title ? this.state.clinic_title: <Translate id="app-title" /> } </div>
        </div>
      </footer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);