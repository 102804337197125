import axios from 'axios';
export const WEB_SERVICE_URL = process.env.REACT_APP_WEB_SERVICE_URL;
//export const WEB_SERVICE_URL='https://dev17.validi.dk/validi-meeting-api';
// export const WEB_SERVICE_URL = "http://localhost/validi/validi-meeting-api";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const MEETING_EVENTS = 'get-meeting-events/index.php';
export const UPDATE_MEETING_STATUS = 'update-meeting-status/index.php';

// Routes 
export const REDICT_ROIUTE = '/';

export const setLocalItem = (key, value) => {
    window.localStorage.setItem(key, value);
};

export const getLocalItem = (key, value) => {
    return window.localStorage.getItem(key);
};

 export const getIp = () => {
    let ip=window.localStorage.getItem('ip');
    if(ip && ip.trim()){
       return ip;
    }else{
        axios.get("https://checkip.amazonaws.com")
       .then((response) => {
            window.localStorage.setItem("ip",response.data);
            return response.data;
        })
        .catch((error) => {
            console.log("Error",error);
        });
    }
}
