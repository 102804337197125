import React from 'react';
import './App.scss';
import englishTranslation from './utils/locale/en.json';
import danishTranslation from './utils/locale/da.json';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import AppLayout from './components/AppLayout/AppLayout';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "en":true,
      "da":false,
    };
    let languages=[];
    
    languages=[
      { name: 'Denish', code: 'da' },
      { name: 'English', code: 'en' },
    ];
    
    this.props.initialize({
      languages: languages,
      translation: {},
      options: { renderToStaticMarkup }
    });


    this.props.addTranslationForLanguage(englishTranslation, 'en');
    this.props.addTranslationForLanguage(danishTranslation, 'da');
  }

  render() {
    return (
      <AppLayout/>
    );
  }
}

export default withLocalize(App);
