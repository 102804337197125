/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import appLayoutReducer from '../containers/AppLayoutContainer/reducer';
import homeReducer from '../containers/HomeContainer/reducer';
import meetingReducer from '../containers/MeetingContainer/reducer';

const createReducer = combineReducers({
    appLayout:appLayoutReducer,
    localize: localizeReducer,
    home:homeReducer,
    meeting:meetingReducer
});

export default createReducer;
