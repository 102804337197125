import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import './Header.scss';
import Home from '../../Home';
import logo from '../../../images/logo.png';
import Favicon from 'react-favicon';
import CryptoJS from "crypto-js";
import * as webConst from '../../../utils/WebConstant';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: '',
      clinic_title: '',
      clinic_short_description: '',
      booking_url: '',
      favicon: '',
      clinic_info: "",
    };
  }
  componentDidMount = () => {
    let clinic_info = webConst.getLocalItem("clinic_info");
    if (clinic_info && clinic_info !== "" && clinic_info !== null && clinic_info !== "null") {
      var bytes = CryptoJS.AES.decrypt(clinic_info.toString(), 'validi@123456');
      clinic_info = bytes.toString(CryptoJS.enc.Utf8);
      clinic_info = JSON.parse(clinic_info);

      if (clinic_info.logo)
        this.setState({ logo: clinic_info.logo });
      else
        this.setState({ logo: logo });
      if (clinic_info.clinic_title)
        this.setState({ clinic_title: clinic_info.clinic_title });
      if (clinic_info.clinic_short_description)
        this.setState({ clinic_short_description: clinic_info.clinic_short_description });
      if (clinic_info.favicon)
        this.setState({ favicon: clinic_info.favicon });
    }
  }

  componentDidUpdate = prevProps => {
    const { homeSuccess, homeStatus } = this.props;
    
    if (homeStatus === 200) {
      if (prevProps.homeSuccess !== homeSuccess) {
        let clinic_info = {
          logo: homeSuccess.logo,
          clinic_title: homeSuccess.clinic_title,
          clinic_short_description: homeSuccess.clinic_description,
          favicon: homeSuccess.favicon
        };

        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(clinic_info), 'validi@123456');
        this.setState(clinic_info);
        webConst.setLocalItem("clinic_info", ciphertext.toString());
      }
    }
  }

  render() {
    
    return (
      <Router>
        {this.state.favicon ? <Favicon url={this.state.favicon} /> : ''}
        <Navbar className="navbar shadow-sm vg-navbar" expand="lg">
          <div className="ml-4">
            <Navbar.Brand href={'#'} className="navbar-brand">
              {
                this.state.logo ?
                  <div>
                    <img src={this.state.logo ? this.state.logo : logo} width="45" alt="" className="d-inline-block align-middle mr-2 logo" />
                    <span className="app-title font-weight-bold"> {this.state.clinic_title ? this.state.clinic_title : <Translate id="app-title" />}</span>
                    <div className="clinic_description"> {this.state.clinic_short_description ? this.state.clinic_short_description : ''}</div>
                  </div>
                  :
                  <div>
                    <img src={logo} width="45" alt="" className="d-inline-block align-middle mr-2 logo" />
                    <span className="app-title font-weight-bold"> <Translate id="app-title" /></span>
                  </div>
              }
            </Navbar.Brand>


            <Navbar.Collapse id="basic-navbar-nav" className="navbar-nav ml-auto">
              
            </Navbar.Collapse>
          </div>
        </Navbar>

        <div className="mt-4 m-3 fh-content">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/:client_id" exact component={Home} />
          </Switch>
        </div>
      </Router >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    homeSuccess: state.home.success,
    homeFail: state.home.fail,
    homeStatus: state.home.status,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);