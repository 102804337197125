import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { getMeetingEventsRequest } from '../../containers/HomeContainer/action';
import { updateMeetingStatusRequest } from '../../containers/MeetingContainer/action';
import moment from 'moment';
import 'moment/locale/da';
import "./Home.scss";
import personCheckInIcon from "../../images/person-check-in.svg";
import personCheckOutIcon from "../../images/person-check-out.svg";
import accessTimeCheckInIcon from '../../images/access-time-green.svg';
import accessTimeOutIcon from '../../images/access-time-red.svg';
import errorIcon from '../../images/error-outline.svg';
import PuffLoader from "react-spinners/PuffLoader";


class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      meetings: [],
      time: moment().format("HH:mm:ss"),
      clinic_id: 0,
      error: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
  }

  componentDidMount() {
    // this.intervalID = setInterval(
    //   () => this.tick(),
    //   20000
    // );
    this.getMeetings();
  }

  async getMeetings() {
    this.setState({ 'isLoading': true });
    let _this = this;
    const params = new URLSearchParams();
    let link_id = this.props.match && this.props.match.params.client_id ? this.props.match.params.client_id : 0;
    params.append('clinicId', link_id);

    if (this.state.clinic_id !== link_id)
      this.setState({ clinic_id: link_id });

    await _this.props.getMeetingEventsRequest(params);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.setState({ meetings: [] });
  }

  tick() {
    this.setState({
      time: moment().format("HH:mm:ss")
    });
  }

  componentDidUpdate(prevProps) {
    const { homeSuccess, homeFail, homeStatus, meetingSuccess, meetingFail, meetingStatus } = this.props;

    if (homeStatus === 200) {
      if (homeSuccess.meetings) {
        if (this.state.meetings!==homeSuccess.meetings && this.state.meetings.length === 0) {
          this.setState({
            meetings: homeSuccess.meetings
          });
        }
      }
      if (prevProps.homeSuccess !== homeSuccess) {
        if (this.state.isLoading) {
          this.setState({
            isLoading: false,
            cols: homeSuccess.showing_in_one_row_client,
            meetingMinutes: homeSuccess.meeting_showing_before_and_after_minute
          });
        }
      }

    } else {
      if (prevProps.homeFail !== homeFail) {
        this.setState({
          meetings: [],
          error: homeFail
        });

        if (this.state.isLoading) {
          this.setState({
            isLoading: false
          });
        }
      }
    }


    //meeting
    if (meetingStatus === 200) {
      if (prevProps.meetingSuccess !== meetingSuccess) {
        if (this.state.meetings !== meetingSuccess.meetings) {
          this.setState({
            isLoading: false,
            meetings: meetingSuccess.meetings
          });
        }
      }
    } else {
      if (prevProps.meetingFail !== meetingFail) {
        if (this.state.isLoading) {
          this.setState({
            isLoading: false
          });
        }
      }
    }
  }

  handleChange = (meeting) => {
    if (this.state.meetings) {
      let meeting_schedules = [];
      meeting_schedules = this.state.meetings.map((item, index) => {
        if (meeting.id === item.id)
          item.status = meeting.status === "1" ? "0" : "1";
        return item;
      });
      let _this = this;
      setTimeout(() => {
        _this.setState({ meetings: meeting_schedules });
        _this.handleUpdateStatus(meeting);
      }, 100);

    }
  }

  handleUpdateStatus = (meeting) => {
    this.setState({ 'isLoading': true });
    const params = new URLSearchParams();
    params.append('meetingId', meeting.id);
    params.append('status', meeting.status);
    params.append('startTime', meeting.startTime);
    params.append('endTime', meeting.endTime);
    params.append('clientId', meeting.clientId);
    this.props.updateMeetingStatusRequest(params);
  }

  createMeetingBox = (meeting,meetingMinutes) => {
    return (
      <div className={"mt-4 m-2 border rounded pointer " + (meeting.status === '0' ? 'border-success text-success' : 'border-danger text-danger')} onClick={() => { this.handleChange(meeting) }}>
        <div className="text-center p-2">
          <img src={meeting.status === '0' ? personCheckInIcon : personCheckOutIcon} className="person" alt="Person" />
          <div>
            <span className={"pl-2 fs-12 align-top"}><Translate id="home.end" /> {moment(meeting.end).add(meetingMinutes, "minutes").from(moment())}</span>
          </div>
        </div>
        <div className="text-capitalize text-center text-muted"><strong>{meeting.clientName}</strong></div>
        <div className={"border-top p-2 " + (meeting.status === '0' ? 'border-success' : 'border-danger')}>
          <img src={meeting.status === '0' ? accessTimeCheckInIcon : accessTimeOutIcon} alt="Time" />
          <span className={"fs-15 pl-2 align-top"}>{moment(meeting.start).subtract(meetingMinutes, "minutes").format('HH:mm')} - {moment(meeting.end).add(meetingMinutes, "minutes").format('HH:mm')}</span>
        </div>
      </div>);
  }

  render() {
    let screenWidth = document.documentElement.clientWidth;
    let meetings = [];
    let meetingSchedules = this.state.meetings ? this.state.meetings : [];
    if (meetingSchedules) {
      meetingSchedules.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      });  
      
      meetingSchedules.forEach((item, index) => {
        let cols = item.clientInRow ? parseInt(item.clientInRow) : 4;
        let meetingMinutes = item.showingMinute ? parseInt(item.showingMinute) : 30;
        let colClassName = screenWidth < 600 ? "col-sm-12 col-xs-12" : "p-1 col-box-" + cols;
  
        let meetingStart = moment(item.start).subtract(meetingMinutes, "minutes").format("HH:mm");
        let meetingEnd = moment(item.end).add(meetingMinutes, "minutes").format('HH:mm');
        
        //console.log(moment().format("HH:mm:ss") +'>='+ moment(item.start).subtract("30","minutes").format("HH:mm:ss"), moment().format("HH:mm:ss") +'<='+ moment(item.end).add("30","minutes").format('HH:mm:ss'));
        if (moment().format("HH:mm") >= meetingStart && moment().format("HH:mm") <= meetingEnd) {
          console.log(item);
          meetings.push(
            <div key={index} className={colClassName}>
              {this.createMeetingBox(item,meetingMinutes)}
            </div>
          );
        }
      });
    }
    return (
      <div className="vg-card-header">
        <div className="fh-main-client">
          <div className="row">
            <div className="col-md-12">

              <div className={"card " + (!this.state.isLoading ? "animated fadeIn" : " card-opacity")}>
                <div className="card-header vg-main-header">
                  <div className="vg-card-title">
                    <div className="row">
                      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <Translate id="home.title" />
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-right d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        <span className="badge badge-success mr-2">&nbsp;&nbsp;</span><small><Translate id="home.check-in" /></small> <span className="badge badge-danger mr-2">&nbsp;&nbsp;</span><small><Translate id="home.check-out" /></small>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 d-lg-block d-sm-none d-md-none d-lg-none d-xl-none">
                        <span className="badge badge-success mr-2">&nbsp;&nbsp;</span><small><Translate id="home.check-in" /></small> <span className="badge badge-danger mr-2">&nbsp;&nbsp;</span><small><Translate id="home.check-out" /></small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body animated fadeIn">
                  {!this.state.isLoading ?
                    <div>
                      {
                        meetings && meetings.length ?
                          <div className="row"> {meetings} </div>
                          :
                          <div className="text-danger text-center">
                            <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block fs-25">
                              <img src={errorIcon} alt="error" />
                              <div>
                                {this.state.error ?
                                  <strong>{this.state.error}</strong>
                                  :
                                  <strong><Translate id="alert-message.no-meeting" /></strong>
                                }
                              </div>
                            </div>
                            <div className="d-lg-block d-sm-none d-md-none d-lg-none d-xl-none fs-12">
                              <img src={errorIcon} height="25px" alt="error" />
                              <div>
                                {this.state.error ?
                                  <strong>{this.state.error}</strong>
                                  :
                                  <strong><Translate id="alert-message.no-meeting" /></strong>
                                }
                              </div>
                            </div>
                          </div>
                      }
                    </div> : ''
                  }
                </div>
              </div>
              {this.state.isLoading ? <PuffLoader color={"#588F1E"} loading={true} css={"display: block; margin: 0 auto;"} size={60} /> : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    homeSuccess: state.home.success,
    homeFail: state.home.fail,
    homeStatus: state.home.status,
    meetingSuccess: state.meeting.success,
    meetingFail: state.meeting.fail,
    meetingStatus: state.meeting.status,
  };
}
const mapDispatchToProps = { getMeetingEventsRequest, updateMeetingStatusRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
